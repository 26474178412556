
import { Component, Vue } from 'vue-property-decorator';
import NativeService from './services/NativeService';
import AuthResponse from './models/AuthResponse';
import VueGtag from "vue-gtag";
import { parseJwt } from '@/utils/stringUtils';
import packageInfo from '../package.json';

require('@/modules/core/assets/css/maps.css');
require('@/modules/core/assets/css/texts.css');
require('@/modules/core/assets/css/transitions.css');
require('@/modules/core/assets/css/countdown.css');
require('@/modules/core/assets/css/animation.css');

@Component({})
class App extends Vue {

    public loaded: boolean = false;
    
    private mounted() {

        // eslint-disable-next-line no-console
        console.info(`App version: ${packageInfo.version}`);

        this.loadApp();
        this.configureGoogleAnalytics();
    }

    private async loadApp(){
        NativeService.MapGoBack();

        let userType: string | null = null;
        let coordinates: string | null = null;

        if (this.$route.name == "external-login") {
            await this.$store.dispatch('clearSession');
        }
        else {
            userType = localStorage.getItem('usertype#pilotarapp');
            coordinates = localStorage.getItem('coordinates#pilotarapp');
        }

        // Verificar se existe um tipo de usuario
        if(userType != null) {
            this.$store.commit('setUserType', userType);
            // Verificar se � um aluno registrado
            if(userType == "alunoRegistrado") {
                // Carregar a sess�o
                const currentSession = localStorage.getItem('session#pilotarapp');                
                if(currentSession != null) {
                    const user = JSON.parse(currentSession) as AuthResponse;

                    //Caso o token tenha expirado, realiza logoff no aplicativo
                    const tokenExpiration = new Date(parseJwt(user.token).exp * 1000);
                    if (tokenExpiration <= new Date()) {
                        this.$pilotarAlert('Sess�o', 'Sua sess�o expirou, realize o login novamente.', [{
                            title: "Ok",
                            action: async () => {
                                try{
                                    this.$store.commit('logout');
                                } catch (e) {
                                    console.error(e);
                                }

                                try{
                                    this.$store.commit('clearStack', { page: 'autoescolas', transition: 'crossFade' });
                                } catch (e) {
                                    console.error(e);
                                }

                                try{
                                    this.$router.replace({ name: 'start' });
                                } catch (e) {
                                    console.error(e);
                                }                                
                            },
                            primary: true
                        }]);
                    }
                    else {
                        this.$store.commit('setUser', user);
                        const stringified = JSON.stringify(user);
                        if(stringified != undefined)
                        {
                            this.$store.dispatch('Sync', stringified);
                        }
                        this.loaded = true;
                    }
                }
                else {
                    this.$router.replace({ name: 'onboardingbemvindo' });
                }
            }
            else if(userType == 'naoAluno' && coordinates != null) {
                NativeService.GetCurrentPosition()
                    .then(resp => this.$store.commit('setCurrentPosition', resp))
                    .catch(() =>  this.$store.commit('setCurrentPosition', JSON.parse(coordinates || '[0,0]')))
                    .finally(() =>  this.loaded = true);
            }
            else {
                this.loaded = true;
            }
        }
        else {
            if(this.$route.name != 'onboardingbemvindo') {
                //this.$router.replace({ name: this.$route.name?.toString() });
            }
            this.loaded = true;
        }

    }

    private configureGoogleAnalytics(){
        Vue.use(VueGtag, {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_METRIC_ID }
        });
    }
}

export default App;
